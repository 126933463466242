import classnames from "classnames";
import { Row } from "antd";
import { useContext, useState, useEffect } from "react";
import Button from "@components/shared/Button";
import CardItem from "./CardItem";
import { AppContext } from "~/src/libs/context";
import { getText } from "@src/libs/resources";
import { useWindowResize } from "@src/utilities/hooks/useWindowResize.js";
import { CardsSectionProps, Card } from "./types";
import styles from "./style.module.scss";

const CategoryList = ({
  sectionTitle,
  subSection,
  btnText = "View All",
  btnUrl = "",
  cardType = "top-categories",
  items,
  AllowedUsers = "Both",
  forMixedPallet = false,
}: CardsSectionProps) => {
  const [isGuestUser, setIsGuestUser] = useState<boolean>(false);
  const { guestUser, customerStatus } = useContext<any>(AppContext);
  const windowWidth = useWindowResize();
  const cardClass = classnames(
    styles.Categories,
    forMixedPallet && styles.featuredVendors
  );

  useEffect(() => {
    setIsGuestUser(guestUser);
  }, [guestUser]);

  return (
    <>
      {isGuestUser && (AllowedUsers === "Guest" || AllowedUsers === "Both") && (
        <Row
          className={`bg-white ${
            AllowedUsers === "Guest" &&
            cardType === "top-categories" &&
            styles.CategoriesWithImage
          } ${
            AllowedUsers === "Guest" &&
            cardType === "shop-by-business" &&
            styles.GuestBusiness
          }`}
        >
          <div
            className={`${styles.CardContainer} container py-35  ${
              cardType === "shop-by-business" && styles.businessContainer
            } ${cardType === "shop-by-brand" && styles.ShopByBrand}`}
          >
            <h2 className={`${styles.SectionTitle} font-bolder-lg lh-sm`}>
              {sectionTitle}
              {cardType === "shop-by-brand" && (
                <Button
                  theme={"link"}
                  href={btnUrl}
                  className={`${styles.CardButton} font-bold-sm lh-sm ms-6`}
                >
                  {btnText}
                </Button>
              )}
            </h2>
            <div className={cardClass}>
              {items?.map(
                (
                  {
                    cardImage,
                    title,
                    redirectUrl = "",
                    backgroundColor,
                    cardDescription = "",
                  }: Card,
                  index
                ) => (
                  <CardItem
                    cardImage={cardImage}
                    title={title}
                    redirectUrl={redirectUrl}
                    backgroundColor={backgroundColor}
                    cardType={cardType}
                    cardDescription={cardDescription}
                    key={index}
                    AllowedUsers={AllowedUsers}
                  />
                )
              )}
            </div>
          </div>
        </Row>
      )}
      {!isGuestUser &&
        (AllowedUsers === "Registered" || AllowedUsers == "Both") &&
        (AllowedUsers === "Registered" && cardType === "welcome-banner" ? (
          <Row
            className={`bg-white ${styles.CategoriesWithImage} ${
              cardType === "welcome-banner" ? styles.WelcomeBanner : ""
            }`}
          >
            <div className={`${styles.CardContainer} container pt-35 pb-18`}>
              <h1
                className={`${
                  windowWidth < 992 ? "font-bolder-h3" : "font-bolder-xl"
                }  mb-4`}
              >
                {customerStatus?.contact_name
                  ? `${sectionTitle} ${
                      customerStatus?.contact_name?.split(" ")?.[0]
                    }`
                  : getText()?.SignUp?.welcomeToEPallet}
              </h1>
              <h2
                className={`font-regular-base ${
                  windowWidth < 992 ? "mb-20" : "mb-25"
                }`}
              >
                {subSection}
              </h2>

              <div className={cardClass}>
                {items?.map(
                  (
                    {
                      cardImage,
                      title,
                      redirectUrl = "",
                      backgroundColor,
                      cardDescription = "",
                    }: Card,
                    index: any
                  ) => (
                    <CardItem
                      cardImage={cardImage}
                      title={title}
                      redirectUrl={redirectUrl}
                      cardType={cardType}
                      backgroundColor={backgroundColor}
                      cardDescription={cardDescription}
                      key={index}
                      AllowedUsers={AllowedUsers}
                    />
                  )
                )}
                {items
                  ?.slice(0, 6 - items?.length)
                  ?.map(
                    (
                      {
                        cardImage,
                        title,
                        redirectUrl = "",
                        backgroundColor,
                        cardDescription = "",
                      }: Card,
                      index: any
                    ) => (
                      <CardItem
                        cardImage={cardImage}
                        title={title}
                        redirectUrl={redirectUrl}
                        cardType={cardType}
                        backgroundColor={backgroundColor}
                        cardDescription={cardDescription}
                        key={index}
                        AllowedUsers={AllowedUsers}
                      />
                    )
                  )}
              </div>
            </div>
          </Row>
        ) : (
          <Row className="bg-white">
            <div
              className={`${styles.CardContainer} container pt-28 pb-10 ${
                styles.shopByBrandCards
              } ${!forMixedPallet ? "mt-32" : "mt-2"}`}
            >
              <h2 className={`${styles.SectionTitle} font-bolder-lg lh-sm`}>
                {sectionTitle}
                <Button
                  theme={"link"}
                  href={btnUrl}
                  className={`${styles.CardButton} font-bold-sm lh-sm ms-6`}
                >
                  {btnText}
                </Button>
              </h2>
              <div className={cardClass}>
                {items?.map(
                  (
                    {
                      cardImage,
                      title,
                      redirectUrl = "",
                      backgroundColor,
                      cardDescription = "",
                    }: Card,
                    index
                  ) => (
                    <CardItem
                      cardImage={cardImage}
                      title={title}
                      redirectUrl={redirectUrl}
                      backgroundColor={backgroundColor}
                      cardType={cardType}
                      cardDescription={cardDescription}
                      key={index}
                      AllowedUsers={AllowedUsers}
                    />
                  )
                )}
              </div>
            </div>
          </Row>
        ))}
    </>
  );
};

export default CategoryList;
