import React from "react";
import { Col, Row, Tabs, Skeleton, List, Empty } from "antd";
import Link from "next/link";
import Field from "../Fields/index";
import characters from "~/src/data/characters";
import { useState, useEffect } from "react";
import BrandItems from "./BrandItems";
import styles from "./style.module.scss";
import { brandProps } from "./types";
import SearchIcon from "@assets/icons/search.svg";
import Icon from "@components/shared/Icon";
import { useRouter } from "next/router";

const BrandPage = ({
  pageTitle,
  vendors,
  isLoading,
  forMixedPallet,
}: brandProps) => {
  const [terms, setTerms] = useState("");
  const router = useRouter()
  const [activeKey, setActiveKey] = useState("-1");
  const [searchResultChar, setSearchResultChar] = useState<any[]>([]);
  const [searchResultArr, setSearchResultArr] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const resultIndex = characters?.length?.toString();

  const setBrandsByChar = (startingChar: string) => {
    const list: any = [];
    searchResultArr?.map((item: any) => {
      !forMixedPallet
        ? item.startsWith(startingChar)
          ? list.push(item)
          : null
        : item?.brand?.startsWith(startingChar)
        ? list.push({ brand: item?.brand, vendor: item?.vendor })
        : null;
    });
    return list;
  };

  const searchOnBrands = () => {
    let chars: any = [];
    const list: any = [];
    searchResultArr?.map((k: any) =>
      !forMixedPallet
        ? !chars?.includes(k?.charAt(0))
          ? chars?.push(k?.charAt(0))
          : null
        : !chars?.includes(k?.brand?.charAt(0))
        ? chars?.push(k?.brand.charAt(0))
        : null
    );
    chars = chars?.sort();
    chars?.map((k: any) => list.push({ key: k, list: setBrandsByChar(k) }));
    chars?.length > 0 ? setActiveKey(resultIndex) : null;
    setSearchResultChar(list);
  };

  const onChangeTabs = (key: string) => {
    const character = characters[parseInt(key)];
    router.push(`${forMixedPallet ? 'mixed-pallet': '/brands'}#brand-${character}`)
    setTerms("");
  };

  useEffect(() => {
    searchOnBrands();
  }, [searchResultArr]);

  useEffect(() => {
    terms?.length > 2 ? setActiveKey(resultIndex) : setActiveKey("-1");
  }, [terms]);

  return (
    <>
      <div
        className={`container bg-white ${styles.brandsListComponent} ${styles.brandContainer}`}
      >
        <Row className="mb-20 pt-30">
          <Col
            className={styles.headerContainer}
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 16 }}
            xl={{ span: 16 }}
          >
            <div className={styles.titleContainer}>
              <h1
                className={`font-bolder-lg lh-sm mb-0 ${styles.title}`}
              >
                {pageTitle}
              </h1>
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
            xl={{ span: 8 }}
            className={styles.searchBoxContainer}
          >
            <Field
              placeholder={
                forMixedPallet ? "Search a vendor" : "Search a brand"
              }
              className={styles.brandSearchBox}
              type="text"
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setTerms(e.currentTarget.value)
              }
              value={terms}
              aria-label="Search a vendor or brand"
              allowClear={{
                clearIcon: <Icon name="cross" classesName="d-flex" />,
              }}
              hasIcon={true}
              Icon={SearchIcon}
              onIconClick={searchOnBrands}
              prefix={true}
            />
          </Col>
        </Row>
        <div className={`${styles.charactersBar}`}>
          <Col className={styles.charactersContainer}>
            <Tabs
              activeKey={activeKey != "-2" ? activeKey : "1"}
              onChange={onChangeTabs}
              className={`${styles.brandTabs}`}
              items={new Array(characters?.length + 1)
                .fill(null)
                ?.map((_, i) => {
                  const id = String(i - 1);

                  return {
                    label: `${characters[i - 1]}`,
                    key: id,
                    children:
                      i === 0 ? (
                        <div className={styles.defaultBrandsContainer}>
                          <>
                            {characters?.map((character, index) => {
                              return (
                                <BrandItems
                                  startingChar={character}
                                  key={index}
                                  setSearchResultArr={setSearchResultArr}
                                  searchTerm={terms}
                                  vendors={vendors}
                                  isLoading={isLoading}
                                  forMixedPallet={forMixedPallet}
                                  activeKey={activeKey}
                                  setIsLoading= {setLoading}
                                ></BrandItems>
                              );
                            })}
                          </>
                        </div>
                      ) : (
                        <BrandItems
                          startingChar={characters[i - 1]}
                          key={i}
                          setSearchResultArr={setSearchResultArr}
                          searchTerm={terms}
                          vendors={vendors}
                          isLoading={isLoading}
                          forMixedPallet={forMixedPallet}
                          activeKey={activeKey}
                          setIsLoading= {setLoading}
                        ></BrandItems>
                      ),
                  };
                })}
            ></Tabs>
          </Col>
        </div>
        {searchResultChar && searchResultChar?.length > 0 ? (
          <>
            <div className="mt-20 font-semibold-h3">Search Result</div>
            {searchResultChar?.map((item, index) => (
              <Row id={`brand-${item?.key}`} key={index}>
                <div
                  className={`mt-30 mb-16 pb-4 ${styles.brandTitleContainer}`}
                >
                  <Skeleton
                    title={false}
                    loading={forMixedPallet ? isLoading : loading}
                  >
                    <span className="oxfordBlue font-bolder-h3 lh-md">
                      {item?.key}
                    </span>
                  </Skeleton>
                </div>
                <List
                  dataSource={item?.list}
                  className={styles.brandList}
                  renderItem={(item: any) => (
                    <Skeleton
                      title={false}
                      loading={forMixedPallet ? isLoading : loading}
                    >
                      <List.Item key={item.id}>
                        <Link
                          href={
                            forMixedPallet
                              ? `mixed-pallet-vendor?ordering=delivered_case_price&vendor=${item?.vendor}`
                              : `/product-list/brands/${item}`
                          }
                        >
                          <a
                            className={`font-regular-md lh-sm ${styles.brandName}`}
                          >
                            {forMixedPallet ? item?.brand : item}
                          </a>
                        </Link>
                      </List.Item>
                    </Skeleton>
                  )}
                />
              </Row>
            ))}
          </>
        ) : (
          <>
            {activeKey === resultIndex && (
              <div>
                <div
                  className={`mt-30 mb-16 pb-4 font-semibold-h3 ${styles.brandTitleContainer}`}
                >
                  Search Result
                </div>
                <Empty
                  imageStyle={{
                    display: "none",
                  }}
                  className={`mt-20 ${styles.noDataMsg}`}
                  description={<span>{"No data"}</span>}
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default BrandPage;
